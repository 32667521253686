.main {
    background: #F1A2B6;
    width: 100%;
    height: 100%;
  }
  
  .container {
    position: absolute; 
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 4px solid #FCD8E8;
    background: #F5C0D7;
    overflow: hidden;
  }
  
  .heart {
    position: absolute;
    width: 150px;
    height: 150px;
    background-color: #F1273F;
    margin: auto;
    top: 30px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    transform: rotate(-45deg);
    animation-name: beat;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  .heart::after {
    position: absolute;
    background-color: #F1273F;
    content: '';
    border-radius: 50%;
    width: 150px;
    height: 150px;
    top: 0px;
    left: 70px;
  }
  
  .heart::before {
    position: absolute;
    background-color: #F1273F;
    content: '';
    border-radius: 50%;
    width: 150px;
    height: 150px;
    top: -70px;
    left: 0px;
  }
  
  @keyframes beat {
    0% {
      transform: scale(1) rotate(-45deg);
    }
    50% {
      transform: scale(0.8) rotate(-45deg);
    }
  }
  
  .footer {
    position: fixed;
    bottom: 0;
    right: 0;
    font-size: '24px';
    text-transform: uppercase;
    padding: 10px;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    color: white;
    letter-spacing: 3px;
  }
  
  .link {
    color: #EC5389;
    text-decoration: none;
  }
  
  .link:hover {
    color: white;
  }